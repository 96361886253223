@import '../../../scss/main';


.ll-input {
    height: 4rem;
    width: 100%;
    .ll-standard-input {

    }

    .ll-standard-input__label {

    }

    .ll-accent-input {

    }

    .ll-accent-input__label {

    }
}