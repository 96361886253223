$ll-modal__content_height: 90vh;

.dx-datagrid
    .dx-datagrid-content
    .dx-datagrid-table
    .dx-row
    > td.dx-command-select {
    padding-left: 4px;
}

.MuiDrawer-root.MuiDrawer-modal {
    z-index: 1600 !important;
}

.dx-datagrid-header-panel {
    padding: 0 14px 0 14px !important;
}

.dx-toolbar-before {
    .dx-toolbar-label {
        max-width: 345px !important;

        .dx-toolbar-item-content {
            height: 40px;
            padding: 0 10px;
            line-height: 22px;
            border: 1px solid #57626b;
            margin-left: 1px;
            display: flex;
            align-items: center;
        }
    }
}

.ll-modal {
    display: flex;

    .ll-fab {
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(40%, -40%);
        font-size: 1.1rem;
        z-index: 99;
    }

    .ll-modal__content {
        width: 90%;
        height: $ll-modal__content_height;
        margin: auto;
        position: relative;
        outline: none;
    }

    &.login-modal {
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active,
        textarea:-webkit-autofill,
        textarea:-webkit-autofill:hover,
        textarea:-webkit-autofill:focus,
        select:-webkit-autofill,
        select:-webkit-autofill:hover,
        select:-webkit-autofill:focus {
            border-bottom: 0.1rem solid #fff !important;
            -webkit-text-fill-color: black !important;
            -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
            transition: background-color 5000000s ease-in-out 0s !important;
        }

        .ll-modal__content {
            text-align: center;
            padding: 10px;
            border: 0;
            background-color: transparent;
            position: relative;
            outline: 0;
            width: 100%;
            margin-left: 0;
            margin-top: 0;
            height: 100vh;
            display: flex;
            align-items: center;
            justify-content: center;

            .login-card {
                padding: 20px;

                .login-form__button {
                    margin: auto;
                }
            }
        }
    }
    .ll-modal__add-admin {
        width: 50%;
        height: 35%;
        background-color: #363640;
    }

    .ll-modal__content_black {
        border: 1px solid black;
        background-color: #363640;
    }

    .ll-modal__content_light {
        border: 1px solid #9c9ca6;
        background-color: #fff;
    }
}

.ll-time-picker-dark {
    div,
    label {
        color: white !important;
    }
}

.ll-time-picker-light {
    div,
    label {
        color: black !important;
    }
}

.dx-datagrid
    .dx-datagrid-content
    .dx-datagrid-table
    .dx-row
    > td.dx-command-select {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.dx-command-select .dx-checkbox-icon {
    background: none !important;
}

@media only screen and (max-width: 900px) {
    .dx-toolbar .dx-toolbar-items-container {
        height: auto !important;

        .dx-toolbar-before,
        .dx-toolbar-center,
        .dx-toolbar-after {
            position: relative;
        }
    }
}

.page-selector.page-selector_light {
    .ll-select {
        color: rgba(0, 0, 0, 0.87);
    }

    .ll-select__svg-icon {
        fill: rgba(0, 0, 0, 0.87);
    }
}

.dx-freespace-row {
    display: none;
}
.ll-spliter__bets {
    height: 84vh !important;
}

.ll-tournaments
    .dx-master-detail-row
    .dx-master-detail-cell
    .dx-datagrid.dx-gridbase-container {
    height: 360px;
}
