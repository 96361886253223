@import '../../../../styles/scss/main';

.MuiPaper-root.ll-select__paper::-webkit-scrollbar {
    display: none;  // Safari and Chrome
}

.MuiSelect-root.ll-select {
    color: #fff;
    font-size: 0.9rem;
    line-height: 2rem;

    .ll-select_input-base {
        padding: 0 1.5rem 0 .5rem;
    }
}

.MuiSvgIcon-root.ll-select__svg-icon {
    fill: #fff;
}