.header {
    display: flex;

    &.header_hidden {
        display: none;
    }

    .ll-header-toolbar_big {
        display: flex;
        justify-content: space-between;
    }

    .header_top-menu_center {
        flex-grow: 1;
        text-align: center;
    }

    .header_top-menu_right {
        display: flex;
        align-items: center;
        width: 480px;

        @media only screen and (max-width: 1024px) {
            width: auto;
            top: 8px;
        }
    }

    .header_buttons-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media only screen and (max-width: 1024px) {
            position: absolute;
            background-color: #3f51b5;
            border-radius: 5px;
            right: 12px;
            top: 57px;
            padding: 5px;
        }
    }

    .header_buttons-container_hidden {
        display: flex;
        align-items: center;

        @media only screen and (max-width: 1024px) {
            display: none;
        }
    }

    .header_right-burger-button {
        cursor: pointer;
        display: none;

        @media only screen and (max-width: 1024px) {
            display: block;
        }
    }

    .header__button {
        margin-left: 10px;
    }
}

.header__drawer-menu {
    min-width: 300px;
    max-width: 300px;

    .menu__item-details {
        padding: 0;
        flex-direction: column;
    }

    a {
        display: block;
        width: 100%;
        line-height: 28px;
        border-top: 1px solid #d3d3d3;
        color: #262626 !important;
        padding: 5px 16px;
        &.active {
            font-weight: bold;
            background: #d3d3d3;
        }
    }

    a:last-of-type {
        border-bottom: 1px solid #d3d3d3;
    }

    .menu__item-panel a {
        background: #f1f1f1;
        padding: 5px 30px;
        &.active {
            padding: 5px 30px 5px 40px;
            background: #d3d3d3;
        }
    }
}

.ll-expansion-panel {
    &.ll-expansion-panel_standard {
        padding: 0;
        margin: 0;
        background-color: transparent;
        box-shadow: none;

        &:first-child,
        &:last-child {
            border-radius: 0;
        }

        &.ll-expansion-panel_expanded_standard {
            margin: 0;
        }

        //.ll-expansion-panel_expanded_standard_dark {
        //    background: #3f51b5;
        //    color: white;
        //}
        //
        //.ll-expansion-panel_expanded_standard_light {
        //    background: #03a9f4;
        //    color: white;
        //}

        .ll-expansion-panel__summary_standard {
            padding: 0;
            margin: 0;
            min-height: 30px;
        }

        .ll-expansion-panel__summary-content_standard {
            padding: 0;
            margin: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-shadow: none;
            min-height: 30px;
            position: relative;
        }

        .ll-expansion-panel__summary_standard > div > div {
            padding: 0;
        }

        .menu__item-summary {
            min-height: 38px;
            max-height: 38px;
        }
    }

    &.ll-expansion-panel__details_standard {
        padding: 0;
        margin: 0;
        background-color: transparent;
    }
}

.ll-header-toolbar_small {
    min-height: 0 !important;
    .header__button {
        margin-top: 2px;
        height: 42px;
    }

    @media only screen and (max-width: 1024px) {
        justify-content: space-between;
    }
}
