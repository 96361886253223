@import '../../styles/scss/main';

.login {
    height: 100vh;

    #login-video {
        position: fixed;
        right: 0;
        bottom: 0;
        min-width: 100%;
        min-height: 100%;
    }

    .login-card {
        position: absolute;
        left:calc(50% - 172px);
        top:20%;
        transition: top .2s ease-in-out;
        &.login-card_bigger {
            top:10%;
        }
    }

    .login-title {
        text-align: center;
    }

    .login-form {
        padding: 0 1rem;

        .login-form__button {
            width: 100%;
            margin: 1rem 0;
        }
    }

    p, h5 {
        margin: 10px 0;
        text-align: center;
    }

    .login__auth-block {
        &.disabled {
            display:none;
        }
    }

}