.reset-users-grid-settings {
  width: 100%;
  height: calc(100vh - 64px);
  display: flex;
  align-items: center;
  justify-content: center;

  .reset-users-grid-settings__card {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
  }

  .reset-users-grid-settings__button {
    margin: 40px;
    width: 200px;
    color: #fff;
    background: #57626b;

    &:hover {
      color: #000;
    }
  }
}

.change-password {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 64px);
  width: 100%;
  .change-password-form {
    padding: 0 1rem;
  }
  .ll-input {
    height: 100%;
    margin: 0.4rem 0;
  }

  .change-password-form__button {
    margin: 1rem 0;
  }
  p,
  h5 {
    text-align: center;
  }
}
