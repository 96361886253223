.logs-main__wrapper {
    margin: auto;
    height: auto;
}

.logs-main__selects {
    display: flex;
    justify-content: space-between
}

.logs-main__translation-select {
    width: 300px;
}

.logs-main__page-select {
    width: 200px;
}