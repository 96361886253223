.home {
    display: flex;
    height: 100%;
    width: 100%;
    min-height: calc(100vh - 64px);
    justify-content: center;
    align-items: center;

    svg {
        // width: 500px;
        width: auto;
        font-weight: bold;
        max-width: 600px;
        height: auto;
    }

    .home__info {
        width: 500px;
    }

    .home__version {
        text-align: right;
        text-transform: uppercase;
        padding-right: 70px;
        color: #577bb5;
        font-weight: bold;
        font-size: 12px;
    }
}
