@import './styles/scss/main';
@import './overrides';

*,
p {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 16px;
}

a,
a:visited,
a:active {
    text-decoration: none;
    color: inherit;
}

.page_header {
    text-align: center;
}

.dx-page {
    // visibility: initial !important;
    outline: none;
}

.page-header-name {
    font-size: 24px;
    white-space: nowrap;
    margin: 10px;
}

.site-content {
    width: 100%;
    //height: calc(100% - 64px);
    height: 100%;
    display: flex;
    justify-content: center;
}

.reset-table-state-btn__dark,
.reset-table-state-btn__light {
    height: 40px !important;
    border-radius: 0 !important;
}

.toggle-toolbar-btn__dark,
.toggle-toolbar-btn__light {
    height: 40px !important;
    border: 1px solid #ffffff70 !important;
    border-radius: 0 !important;
    color: #fff !important;
}

.reset-table-state-btn__light {
    border: 1px solid #576267 !important;
}

.reset-table-state-btn__light .dx-icon {
    line-height: 35px !important;
}

.reset-table-state-btn__dark svg {
    fill: rgba(255, 255, 255, 0.6);
}

.reset-table-state-btn__light svg {
    fill: rgba(0, 0, 0, 0.54);
}

.reset-table-state-btn__white .dx-button-content {
    display: flex;
    align-items: center;
}

.template-custom-header-component {
    height: 40px;
    padding: 0 10px;
    line-height: 40px;
    border: 1px solid #57626b;

    @media only screen and (max-width: 1024px) {
        span {
            font-size: 9px;
        }
    }

    button {
        display: inline-block;
        padding: 0;
        margin: -4px 0 0 0;
    }
}

.bets__header {
    height: 48px;
    display: flex;
    align-items: center;
    background-color: #3f51b5;
    margin-bottom: 10px;
}
